import * as React from 'react'

import { Cell, Grid } from 'react-foundation'
import { BeatLoader } from 'react-spinners'
import Product from './Product'

import { extractTagSlug } from '../utils'

interface IProps {
  products: IProduct[]
  loading: boolean
  hideText: boolean
  thumbnailType?: string
  showEmptyMessage: boolean
  emptyMessageText: string
  showLinkAction: boolean
  linkActionText: string
  onLinkActionClick: (e: React.SyntheticEvent) => void
  bucketTitle?: string
}

const GRID_DEFAULT = ''
const GRID_OVERRIDES: { [key: string]: string } = {
  'shoots': 'margin-y-collapse',
  'stock-photos': 'margin-y-collapse',
  'stock-videos': 'margin-y-collapse',
}

const ProductGrid: React.FunctionComponent<IProps> = ({
  products,
  loading,
  hideText,
  thumbnailType,
  showEmptyMessage,
  emptyMessageText,
  showLinkAction,
  linkActionText,
  onLinkActionClick,
  bucketTitle = '',
}) => {
  const productCells = products.map((product: IProduct) => {
    return (
      <Product
        key={product.id}
        product={product}
        hideText={hideText}
        thumbnailType={thumbnailType}
      />
    )
  })

  const messageClass = showEmptyMessage ? 'show' : 'hide'

  const linkAction = showLinkAction ? (
    <a onClick={onLinkActionClick}>{linkActionText}</a>
  ) : null

  const spinner = loading ? (
    <div className="spinner">
      <BeatLoader color={'#222222'} size={18} />
    </div>
  ) : null

  const navTagSlug = extractTagSlug({ tag: products[0] ? products[0].site_nav_parent_tag : undefined })
  const gridClass = GRID_OVERRIDES[navTagSlug] || GRID_DEFAULT
  const bucketClass = bucketTitle.toLowerCase().split(' ').pop()

  return (
    <Grid className={`product-grid-container grid-margin-x grid-margin-y ${navTagSlug}-container ${gridClass} ${bucketClass}`}>
      {spinner}

      {productCells}

      <Cell className={`empty-message text-center ${messageClass}`}>
        <h5>{emptyMessageText}</h5>
        {linkAction}
      </Cell>
    </Grid>
  )
}

export { ProductGrid }
